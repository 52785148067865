// components/TwitterFollowModal.js
"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import "./TwitterFollow.css";

const TwitterFollowModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // 15 saniye sonra modal'ı göster
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalVisible(true);
    }, 60000); // 60 saniye (60000 milisaniye)

    return () => clearTimeout(timer); // Bileşen unmounted olduğunda temizle
  }, []);

  // Modal'ı kapatmak için fonksiyon
  const closeModal = () => {
    setIsModalVisible(false);
  };

  // Boşluğa tıkladığında modal'ı kapatmak için overlay'e onClick ekle
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  // Linke tıkladığında modal'ı kapatmak için fonksiyon
  const handleLinkClick = () => {
    closeModal();
  };

  // Modal kapalıysa, boş bir fragment döndür
  if (!isModalVisible) return null;

  return (
    <div className="twitter-follow-modal-overlay" onClick={handleOverlayClick}>
      <div className="twitter-follow-modal-content">
        <button className="twitter-follow-close-button" onClick={closeModal}>
          ×
        </button>

        <p>
          <strong>Dikkat:</strong> Dolandırıcılara karşı tedbirli olunuz.
        </p>
        <p>
          USC Markets’in <strong>WhatsApp, Telegram</strong> veya benzeri özel
          grupları{" "}
          <ins>
            <strong>bulunmamaktadır</strong>.
          </ins>
        </p>
        <p>USC Markets asla size doğrudan mesaj göndermez.</p>
        <p>
          Lütfen şüpheli mesajları aşağıdaki resmi hesaplarımıza bildiriniz.
        </p>

        {/* Twitter Linki */}
        <a
          href="https://twitter.com/intent/follow?screen_name=uscmarkets"
          target="_blank"
          onClick={handleLinkClick}
          className="social-link"
        >
          <span className="twitter-follow-twitter-span">
            <Image
              src="/assets/logo-twitter.svg"
              alt="Twitter logosu"
              width={24}
              height={24}
              className="twitter-follow-twitter-logo"
            />
            Twitter'da Takip Edin: @UscMarkets
          </span>
        </a>

        {/* Instagram Linki */}
        <a
          href="https://www.instagram.com/uscmarkets/"
          target="_blank"
          onClick={handleLinkClick}
          className="social-link"
        >
          <span className="twitter-follow-instagram-span">
            <Image
              src="/assets/icons8-instagram.svg"
              alt="Instagram logosu"
              width={24}
              height={24}
              className="twitter-follow-instagram-logo"
            />
            Instagram'da Takip Edin: @UscMarkets
          </span>
        </a>

        {/* YouTube Linki */}
        <a
          href="https://www.youtube.com/@uscmarkets?sub_confirmation=1"
          target="_blank"
          onClick={handleLinkClick}
          className="social-link"
        >
          <span className="twitter-follow-youtube-span">
            <Image
              src="/assets/icons8-youtube.svg"
              alt="YouTube logosu"
              width={24}
              height={24}
              className="twitter-follow-youtube-logo"
            />
            YouTube' da Takip Edin: @UscMarkets
          </span>
        </a>
      </div>
    </div>
  );
};

export default TwitterFollowModal;
